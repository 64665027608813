import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { Craft } from '../models/craft';
import { map, switchMap, filter } from 'rxjs/operators';
import { MaterialsService } from './materials.service';

@Injectable({
  providedIn: 'root'
})
export class CraftsService {
  public crafts: BehaviorSubject<Craft[]> = new BehaviorSubject<Craft[]>([]);

  constructor(
    private db: AngularFirestore,
    private materialsService: MaterialsService
  ) {}

  public getCrafts(): Observable<void> {
    return this.db
      .collection('crafts')
      .valueChanges({ idField: 'id' })
      .pipe(
        map((crafts: Craft[]) => {
          crafts.forEach(craft => {
            this.materialsService.materials.subscribe(materials => {
              craft.materials.forEach(materialListEntry => {
                materialListEntry.material = materials.filter(
                  x => x.id == materialListEntry.materialId
                )[0];
              });
            });
          });
          this.crafts.next(crafts);
        })
      );
  }
}
