import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// Firebase
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';

import { FontAwesomeLibraryModule } from './font-awesome-library/font-awesome-library.module';

import { CraftsComponent } from './pages/crafts/crafts.component';
import { ErrorComponent } from './pages/error/error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MaterialsComponent } from './pages/materials/materials.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { NavigationService } from './services/navigation.service';
import { MaterialsService } from './services/materials.service';
import { AuthService } from './services/auth.service';
import { CraftsService } from './services/crafts.service';
import { CraftCardComponent } from './components/craft-card/craft-card.component';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { EditCraftComponent } from './components/edit-craft/edit-craft.component';
import { EditMaterialComponent } from './components/edit-material/edit-material.component';
import { SettingsService } from './services/settings.service';

@NgModule({
  declarations: [
    AppComponent,
    CraftsComponent,
    ErrorComponent,
    PageNotFoundComponent,
    MaterialsComponent,
    SettingsComponent,
    UserMenuComponent,
    CraftCardComponent,
    ConfirmDeleteDialogComponent,
    EditCraftComponent,
    EditMaterialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    FontAwesomeLibraryModule
  ],
  entryComponents: [ConfirmDeleteDialogComponent, EditCraftComponent, EditMaterialComponent],

  providers: [NavigationService, MaterialsService, CraftsService, AuthService, SettingsService],
  bootstrap: [AppComponent]
})
export class AppModule {}
