import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CraftsComponent } from './pages/crafts/crafts.component';
import { ErrorComponent } from './pages/error/error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MaterialsComponent } from './pages/materials/materials.component';

const routes: Routes = [
  { path: 'crafts', component: CraftsComponent },
  { path: 'materials', component: MaterialsComponent},
  { path: 'settings', component: SettingsComponent },
  { path: '', redirectTo: '/crafts', pathMatch: 'full' },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
