import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit() {}

  login() {
   this.authService.loginWithGoogle();
  }

  logout() {
    this.authService.logout();
    window.location.reload();
  }
}
