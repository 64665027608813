import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faSignInAlt,
  faSignOutAlt,
  faSearch,
  faInfo,
  faExclamation,
  faCheck,
  faPlus,
  faTimes,
  faArrowLeft,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

// // Replace any existing <i> tags with <svg> and set up a MutationObserver to
// // continue doing this as the DOM changes.
// dom.watch();

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontAwesomeLibraryModule {
  constructor(library: FaIconLibrary) {
    // Add our in-use icons
    library.addIcons(faSignInAlt);
    library.addIcons(faSignOutAlt);
    library.addIcons(faSearch);
    library.addIcons(faInfo);
    library.addIcons(faExclamation);
    library.addIcons(faCheck);
    library.addIcons(faPlus);
    library.addIcons(faTimes);
    library.addIcons(faArrowLeft);
    library.addIcons(faBars);
  }
}
