import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { Material } from '../models/material';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  public materials: BehaviorSubject<Material[]> = new BehaviorSubject<
    Material[]
  >([]);

  constructor(private db: AngularFirestore) {}

  public getMaterials(): Observable<void> {
    return this.db
      .collection('materials')
      .valueChanges({ idField: 'id' })
      .pipe(
        map((materials: Material[]) => {
          materials.forEach(material => {
            material.costPerItem =
              Math.round((material.cost / material.itemCount) * 100) / 100;
          });
          this.materials.next(materials);
        })
      );
  }
}
