import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialsService } from 'src/app/services/materials.service';
import { Material } from 'src/app/models/material';
import { MatDialog } from '@angular/material/dialog';
import { EditMaterialComponent } from 'src/app/components/edit-material/edit-material.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {
  public displayedColumns: string[] = [
    'name',
    'itemCount',
    'cost',
    'costPerItem'
  ];
  public dataSource: MatTableDataSource<Material>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public materialsService: MaterialsService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.materialsService.materials.subscribe(
      materials => {
        this.dataSource = new MatTableDataSource(materials);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error => {
        console.error('Error initialising materials page:', error);
      }
    );
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public edit(material: Material): void {
    const dialogRef = this.dialog.open(EditMaterialComponent, {
      // width: '250px',
      data: { material: material }
    });
  }

  public newMaterial() {
    const dialogRef = this.dialog.open(EditMaterialComponent, {
      // width: '250px',
      data: { isNew: true }
    });
  }
}
