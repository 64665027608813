import { Component, OnInit, ViewChild } from '@angular/core';
import { CraftsService } from 'src/app/services/crafts.service';
import { Craft } from 'src/app/models/craft';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-crafts',
  templateUrl: './crafts.component.html',
  styleUrls: ['./crafts.component.scss'],
})
export class CraftsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = ['name', 'materialCost', 'cost'];
  public dataSource: MatTableDataSource<Craft>;

  public createNew = true;
  public isEditing = false;
  public selectedCraft: Craft = null;

  constructor(public craftsService: CraftsService, public dialog: MatDialog) {}

  async ngOnInit() {
    this.refresh();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public edit(craft: Craft): void {
    this.selectedCraft = craft;
    this.createNew = false;
    this.isEditing = true;
  }

  public newCraft() {
    this.selectedCraft = new Craft();
    this.createNew = true;
    this.isEditing = true;
  }

  public refresh() {
    this.craftsService.crafts.subscribe(
      (crafts) => {
        this.dataSource = new MatTableDataSource(crafts);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        console.error('Error initialising crafts page:', error);
      }
    );
  }

  public handleCloseEdit(reload: boolean = false) {
    this.isEditing = false;
    if (reload) {
      this.refresh();
    }
  }
}
