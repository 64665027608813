import { Component, OnInit } from '@angular/core';
import {
  AngularFirestoreDocument,
  AngularFirestore
} from '@angular/fire/firestore';
import { Settings } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public settings: Settings;
  public editSettings: Settings;
  private settingsDoc: AngularFirestoreDocument<Settings>;
  public modelChange = false;

  constructor(
    private db: AngularFirestore,
    public settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.settingsService.settings.subscribe(settings => {
      this.settingsDoc = this.db
        .collection('settings')
        .doc<Settings>(settings.id);
      this.settings = settings;
      this.reset();
    });
  }

  public reset(): void {
    this.editSettings = JSON.parse(JSON.stringify(this.settings));
    this.modelChange = false;
  }

  public async save(): Promise<void> {
    await this.settingsDoc.update(this.editSettings);
  }
}
