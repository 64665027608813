// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCClGhXa5K2SpB71ZgSXAlu02-1JugbUpw",
    authDomain: "leowithlove-cf414.firebaseapp.com",
    databaseURL: "https://leowithlove-cf414.firebaseio.com",
    projectId: "leowithlove-cf414",
    storageBucket: "leowithlove-cf414.appspot.com",
    messagingSenderId: "668970424587",
    appId: "1:668970424587:web:411d5342ad1221ab07aa0e"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
