import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authState: Observable<firebase.User>;
  private currentUser: firebase.User = null;

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    // private localStorage: LocalStorageService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.authState = this.afAuth.authState;

    this.authState.subscribe(
      user => {
        if (user) {
          if (
            user.email != 'ashcarr92@gmail.com' &&
            user.email != 'gbeecham21@gmail.com'
          ) {
            console.warn('Access Denied');
            this.logout('You do not have access to this site');
          }

          this.currentUser = user;
          // this.localStorage.storeSimple('userData', user);
          this.openSnackBar('Successfully signed in');
        } else {
          this.currentUser = null;
        }
      },
      err => {
        this.openSnackBar(
          `${err.status} ${err.statusText} (${err.error.message})`,
          'Please try again'
        );
      }
    );
  }

  isAuthenticated(): boolean {
    return this.authState !== null;
  }

  loginWithGoogle() {
    this.afAuth.auth
      .signInWithPopup(new auth.GoogleAuthProvider())
      .then(u => {
        if (
          u.user.email != 'ashcarr92@gmail.com' &&
          u.user.email != 'gbeecham21@gmail.com'
        ) {
          console.warn('Access Denied');
          this.logout('You do not have access to this site');
        }
      })
      .catch(error => {
        let errorCode = error.code;
        let errorMessage = error.message;
        this.openSnackBar(error.message, 'OK');
      });
  }

  logout(message?: string) {
    this.afAuth.auth
      .signOut()
      .then(response => this.openSnackBar(message ? message : 'Signed out'))
      .catch(error => this.openSnackBar('Error signing out: ' + error));
  }

  openSnackBar(text: string, buttonText?: string): void {
    if (buttonText) {
      this.snackBar.open(text, buttonText, { panelClass: 'success' });
    } else {
      this.snackBar.open(text, null, { duration: 3 * 1000 });
    }
  }
}
