import { Component, OnInit, ViewChild } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable, onErrorResumeNext, forkJoin } from 'rxjs';
import { map, shareReplay, withLatestFrom, filter } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { MaterialsService } from './services/materials.service';
import { CraftsService } from './services/crafts.service';
import { Router, NavigationEnd } from '@angular/router';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer;

  public isLoaded: boolean = false;
  collapse$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    private settingsService: SettingsService,
    private materialsService: MaterialsService,
    private craftsService: CraftsService,
    private router: Router
  ) {
    router.events
      .pipe(
        withLatestFrom(this.collapse$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe(_ => this.drawer.close());
  }

  public ngOnInit() {
    this.authService.afAuth.user.subscribe(user => {
      if (user) {
        this.settingsService.getSettings().subscribe(
          () => {
            this.materialsService.getMaterials().subscribe(
              () => {
                this.craftsService.getCrafts().subscribe(
                  () => {
                    this.isLoaded = true;
                  },
                  error => {
                    this.handleError(error);
                  }
                );
              },
              error => {
                this.handleError(error);
              }
            );
          },
          error => {
            this.handleError(error);
          }
        );
      }
    });
  }

  public handleError(error) {
    console.error(error);
    this.router.navigate(['error']);
  }
}
