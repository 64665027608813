import { Observable } from 'rxjs';

export class Material {
  public id: string;
  public name: string;
  public cost: number;
  public itemCount: number;
  public costPerItem?: number;
}

export class MaterialList {
  count: number;
  materialId: string;
  material: Material;
}
