import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { Material } from 'src/app/models/material';
import {
  AngularFirestoreDocument,
  AngularFirestore
} from '@angular/fire/firestore';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'app-edit-material',
  templateUrl: './edit-material.component.html',
  styleUrls: ['./edit-material.component.scss']
})
export class EditMaterialComponent implements OnInit {
  public editMaterial: Material;
  private materialDoc: AngularFirestoreDocument<Material>;
  public modelChange = false;

  constructor(
    private db: AngularFirestore,
    public dialogRef: MatDialogRef<EditMaterialComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { material: Material; isNew: boolean },
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.data.isNew) {
      this.materialDoc = this.db
        .collection('materials')
        .doc<Material>(this.data.material.id);
    }
    this.reset();
  }

  public reset(): void {
    if (this.data.isNew) {
      this.editMaterial = new Material();
    } else {
      this.editMaterial = JSON.parse(JSON.stringify(this.data.material));
    }
    this.modelChange = false;
  }

  public close(res?: any): void {
    this.dialogRef.close(res);
  }

  public async save(): Promise<void> {
    if (this.data.isNew) {
      await this.db
        .collection('materials')
        .add(Object.assign({}, this.editMaterial))
        .then(newDoc => {
          this.close(newDoc.id);
        });
    } else {
      await this.materialDoc.update(this.editMaterial);
      this.close(true);
    }
  }

  public async delete(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { id: this.editMaterial.id }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.materialDoc.delete();
        this.close();
      }
    });
  }
}
