import { MaterialList } from './material';
import * as moment from 'moment';

export class Craft {
  public id: string;
  public name: string;
  public materials: MaterialList[];
  public time: string;
  public cost: number;
  public sellPrice: number;
  public materialCost: number;
  public timeCost: number;

  constructor() {
    this.time = '0h0m';
    this.materials = [];
    this.name = "New Craft"
    this.id = new Date().toISOString();
  }
}
