import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Settings } from '../models/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public settings: BehaviorSubject<Settings> = new BehaviorSubject<Settings>(null);

  constructor(private db: AngularFirestore) {}

  public getSettings(): Observable<void> {
    return this.db
      .collection('settings', ref => ref.limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(
        map((settings: Settings[]) => {
          this.settings.next(settings[0]);
        })
      );
  }
}
