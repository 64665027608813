import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Craft } from '../../models/craft';
import { MaterialsService } from '../../services/materials.service';
import { SettingsService } from '../../services/settings.service';

import {
  AngularFirestoreDocument,
  AngularFirestore
} from '@angular/fire/firestore';
import { MaterialList, Material } from '../../models/material';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'app-craft-card',
  templateUrl: './craft-card.component.html',
  styleUrls: ['./craft-card.component.scss']
})
export class CraftCardComponent implements OnInit {
  @Input() craft: Craft;
  @Input() isNew: boolean = false;
  @Output() saved = new EventEmitter<string>();

  public editCraft: Craft;
  private craftDoc: AngularFirestoreDocument<Craft>;
  public modelChange = false;
  public isEditing = false;
  public isTimeValid = true;

  constructor(
    private db: AngularFirestore,
    public materialsService: MaterialsService,
    public settingsService: SettingsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.isNew) {
      this.edit();
    } else {
      this.craftDoc = this.db.collection('crafts').doc<Craft>(this.craft.id);
    }
    this.reset();
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public edit(): void {
    this.reset();
    this.isEditing = true;
  }

  public reset(): void {
    this.editCraft = JSON.parse(JSON.stringify(this.craft));
    this.modelChange = false;
    this.recalculate();
  }

  public close(): void {
    this.isEditing = false;
    this.modelChange = false;
    if (this.isNew) {
      this.saved.emit(this.editCraft.id);
    }
  }

  public async save(): Promise<void> {
    this.editCraft.materials.map(materialList => {
      delete materialList.material;
      return materialList;
    });
    if (this.isNew) {
      delete this.editCraft.id;
      await this.db
        .collection('crafts')
        .add(Object.assign({}, this.editCraft))
        .then(newDoc => {
          this.saved.emit(newDoc.id);
        });
    } else {
      await this.craftDoc.update(this.editCraft);
    }
    this.close();
  }

  public removeMaterial(materialList: MaterialList): void {
    if (materialList.materialId) this.modelChange = true;
    this.editCraft.materials = this.editCraft.materials.filter(
      x => x.materialId != materialList.materialId
    );
    this.recalculate();
  }

  public materialChange(event: any, materialList: MaterialList): void {
    this.modelChange = true;
    this.recalculate();
  }

  public newMaterial(): void {
    // If we instantiate a new MaterialList() and push, Firebase complains about a custom object.
    // This is a workaround to use Types still, use object.assign to strip the class info
    this.editCraft.materials.push(Object.assign({}, new MaterialList()));
  }

  public isMaterialInUse(material: Material): Boolean {
    return (
      this.editCraft.materials.filter(x => x.materialId == material.id).length >
      0
    );
  }

  public async recalculate(): Promise<void> {
    // Get our vars from our settings BehaviourSubject
    this.settingsService.settings.subscribe(
      settings => {
        // Refresh the materials with their IDs
        this.materialsService.materials.subscribe(materials => {
          this.editCraft.materials.forEach(materialList => {
            materialList.material = materials.filter(
              x => x.id == materialList.materialId
            )[0];
          });
        });

        // Material cost
        let materialCost: number = 0;
        this.editCraft.materials.forEach(materialList => {
          materialCost =
            materialCost +
            Math.round(
              (materialList.material.cost / materialList.material.itemCount) *
                (materialList.count ? materialList.count : 1) *
                100
            ) /
              100;
        });
        this.editCraft.materialCost = materialCost;

        // Time
        try {
          const timeSplit = this.editCraft.time.split(/h/i);
          let minutes = timeSplit[1].split(/m/i)[0];
          let hours = parseInt(timeSplit[0]);
          hours = hours + Math.round((parseInt(minutes) / 60) * 100) / 100;
          if (isNaN(hours)) throw 'Invalid entry';
          this.editCraft.timeCost =
            Math.round(hours * settings.hourlyRate * 100) / 100;

          // Costs
          this.editCraft.cost =
            this.editCraft.materialCost + this.editCraft.timeCost;
          this.editCraft.sellPrice =
            Math.round(
              this.editCraft.materialCost * settings.materialMultiplier * 100
            ) /
              100 +
            this.editCraft.timeCost;
          this.isTimeValid = true;
        } catch (exception) {
          console.error('Failed parsing time:', exception);
          this.editCraft.cost = materialCost;
          this.editCraft.sellPrice =
            Math.round(materialCost * settings.materialMultiplier * 100) / 100;
          this.isTimeValid = false;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  public async delete(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { id: this.editCraft.id }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.craftDoc.delete();
      }
    });
  }
}
